export interface Application {
  id: string
  applicationId: string
  title: string
  description: string
  logoData: string
  link: string
  edgeRequired: boolean
}

export const ADMIN_GROUPS = [
  'App_Okta_Portal - Admin',
  'App_Okta_Portal_DEV - Admin',
  'App_Okta_Portal_PreProd - Admin',
  'App_Okta_Portal_UAT - Admin',
] as const
