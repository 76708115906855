import { gridClasses } from '@mui/x-data-grid'

// remove focus outline on cells
export const gridCellStyle = {
  [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
    outline: 'transparent',
  },
  [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
    {
      outline: 'none',
    },
}
