import { Box, Button, Typography, CircularProgress } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { CustomUserClaim } from '@okta/okta-auth-js'

import { API } from '../services/endpoints'
import { Application, ADMIN_GROUPS } from '../Types/Application'
import { ReactComponent as Bet365LogoBlack } from '../assets/Bet365LogoBlack.svg'
import { ReactComponent as Bet365Logo } from '../assets/Bet365LogoWhite.svg'
import { ReactComponent as EmployeePortalLogo } from '../assets/EmployeePortalLogo.svg'
import { ReactComponent as EmployeePortalLogoGreen } from '../assets/EmployeePortalLogoGreen.svg'
import { ReactComponent as LockIcon } from '../assets/IconFeatherLock.svg'

export const Applications: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth()
  const [userRoles, setUserRoles] = useState<CustomUserClaim[]>([])
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(true)
  const [error, setError] = useState<string | null>(null)
  const [applications, setApplications] = useState<Application[]>([])

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        console.log('portal_roles: ', info['portal_roles'])
        if (info['portal_roles']) {
          setUserRoles(info['portal_roles'] as CustomUserClaim[])
        }
      })
    }
  }, [authState])

  const isAdmin = userRoles.some((roleClaim) =>
    ADMIN_GROUPS.some((group) => roleClaim.toString().includes(group)),
  )

  useEffect(() => {
    const fetchApplications = async () => {
      setLoading(true)
      try {
        const response = await API.GET('/Employee/applications')
        setApplications(response.applications || [])
      } catch (error) {
        setError('Failed to load applications')
        console.error('Error:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchApplications()
  }, [])

  const handleLaunchApp = (app: Application) => {
    const isEdge = navigator.userAgent.includes('Edg')
    if (!isEdge && app.edgeRequired) {
      if (isMobile) {
        window.open(`microsoft-edge:${app.link}`, '_blank')
      } else {
        window.open(`microsoft-edge:${app.link}`, '_blank')
      }
    } else {
      window.open(app.link, '_blank')
    }
  }

  const handleLogout = async () => {
    await oktaAuth.signOut()
  }

  const sortedApps = [...applications].sort((a, b) =>
    a.title.localeCompare(b.title),
  )

  return (
    <>
      <Box className='applications'>
        <div className='applications__employeeLogoContainer'>
          <EmployeePortalLogoGreen className='applications__employeeLogo' />
        </div>
        <div className='applications__LeftContainer'>
          <div className='applications__aside-gradient' />
          <div className='applications__aside'>
            <div className='applications__aside-content'>
              <div className='applications__aside-logoContainer'>
                <EmployeePortalLogo className='applications__aside-exployeePortalLogo' />
                <Typography
                  variant='h4'
                  component='h1'
                  className='applications__aside-title'
                >
                  MY{' '}
                  <Box
                    component='span'
                    className='applications__aside-title--highlight'
                  >
                    APPS
                  </Box>
                </Typography>
              </div>
              <Bet365Logo className='applications__aside-bet365logo' />
            </div>
          </div>
        </div>
        <Box className='applications__content'>
          <div className='applications__container'>
            {loading ? (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='200px'
              >
                <CircularProgress />
              </Box>
            ) : error ? (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='200px'
              >
                <Typography color='error'>{error}</Typography>
              </Box>
            ) : sortedApps.length === 0 ? (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='200px'
              >
                <Typography>No applications available</Typography>
              </Box>
            ) : (
              sortedApps.map((app) => (
                <div
                  className='applications__card'
                  key={app.applicationId}
                  onClick={() => handleLaunchApp(app)}
                >
                  <img
                    src={app.logoData}
                    alt='App Icon'
                    className='applications__card-icon'
                  />
                  <div className='applications__card-content'>
                    <Typography className='applications__card-contentFont'>
                      {app.title}
                    </Typography>
                  </div>
                </div>
              ))
            )}
          </div>
          <Box className='applications__footer'>
            <Box className='applications__footer-gradientContainer'>
              <div className='applications__footer-gradient' />
            </Box>
            <div className='applications__footer-logos'>
              <Bet365LogoBlack className='applications__footer-logos-bet365' />
              <EmployeePortalLogoGreen className='applications__footer-logos-portal' />
            </div>
            <div className='applications__footer-logos-triangles' />
          </Box>
          <Box className='applications__logout'>
            <Button
              onClick={handleLogout}
              className='applications__logout-button'
            >
              <LockIcon /> <span>Logout</span>
            </Button>
            {isAdmin && (
              <Button
                onClick={() => navigate('/Administration')}
                className='applications__logout-button'
              >
                <span>Administration</span>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Applications
