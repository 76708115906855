export interface AccessList {
  windowsLogin: string
  applicationId?: string // UUID
  accessGranted: boolean
}

export interface ExclusionList {
  windowsLogin: string
  applicationId?: string // UUID
  exclusionListId?: string
}

export interface AdGroupList {
  name?: string
  groupName?: string
  applicationId?: string // UUID
  activeDirectoryGroupId?: string
}

export interface RowData {
  id: string
  applicationId: string
  title: string
  description: string
  logoData: string
  link: string
  isSAML: boolean
}

export interface FormValues {
  data: {
    id: string
    applicationId: string
    title: string
    description: string
    logoData: string
    link: string
    isSAML: boolean
    isDuplicate: boolean
  }
}

export const formFields = [
  {
    id: 'name',
    name: 'Title',
    label: 'Title',
    type: 'text',
    value: 'Title',
    placeholder: 'Enter title of application',
  },
  {
    id: 'description',
    name: 'Description',
    label: 'Description',
    type: 'text',
    value: 'Description',
    placeholder: 'Enter description of application',
  },
  {
    id: 'logo',
    name: 'Logo',
    label: 'Logo',
    type: 'file',
    value: 'LogoData',
    placeholder: 'Upload logo of application',
  },
  {
    id: 'url',
    name: 'Link',
    label: 'URL',
    type: 'text',
    value: 'Link',
    placeholder: 'Enter URL to application',
  },
  {
    id: 'action',
    name: 'action',
    value: 'UserAccess', // action buttons
  },
  {
    id: 'access',
    name: 'UserAccess',
    label: 'Access List',
    type: 'text',
    value: 'UserAccess',
    multiline: false,
    placeholder: 'Enter windows login',
  },
  {
    id: 'exclusion',
    name: 'Exclusion',
    label: 'Exclusion List',
    type: 'text',
    value: 'Exclusion',
    placeholder: 'Enter windows login',
  },
  {
    id: 'adgroups',
    name: 'AdGroup',
    label: 'AD Groups',
    type: 'text',
    value: 'AdGroup',
    placeholder: 'Enter AD group name',
  },
]
