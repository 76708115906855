import * as React from 'react'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'

import { API } from '../services/endpoints'
import {
  FormValues,
  RowData,
  formFields,
  AccessList,
  ExclusionList,
  AdGroupList,
} from '../definitions/gridDef'
import { CreateEditApplication } from '../components/CreateEditApplication'
import { AdminButton } from '../components/Styled/Administration'
import { gridCellStyle } from '../utils/gridUtils'
import DialogWarning from './DialogWarning'
import DialogViewAccess from './DialogViewAccess'

export const ApplicationList: React.FC = () => {
  const [loading, setLoading] = React.useState(true)
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false)
  const [selectedRow, setSelectedRow] = React.useState<RowData | null>(null)
  const [isWarningDialogOpen, setIsWarningDialogOpen] = React.useState(false)
  const [isViewAccessDialogOpen, setIsViewAccessDialogOpen] =
    React.useState(false)
  // Add these new state variables to track newly added items
  const [newAccessItems, setNewAccessItems] = React.useState<AccessList[]>([])
  const [newExclusionItems, setNewExclusionItems] = React.useState<
    ExclusionList[]
  >([])
  const [newAdGroupItems, setNewAdGroupItems] = React.useState<AdGroupList[]>(
    [],
  )

  const [accessList, setAccessList] = React.useState<AccessList[]>([])
  const [exclusionList, setExclusionList] = React.useState<ExclusionList[]>([])
  const [adGroupList, setAdGroupList] = React.useState<AdGroupList[]>([])

  const deleteApplication = async (applicationId: string) => {
    try {
      await API.DELETE(`/Admin/applications/${applicationId}`)
      fetchApplications()
    } catch (error) {
      console.error('Error deleting application:', error)
    }
  }

  const fetchApplications = async () => {
    try {
      setLoading(true)
      const response = await API.GET('/Admin/applications')
      const applicationsWithId = response.applications.map((app: any) => ({
        ...app,
        id: app.applicationId, // Use applicationId as the unique id
      }))
      setGridRows(applicationsWithId)
    } catch (error) {
      console.error('Error fetching applications:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchApplicationById = async (id: string) => {
    try {
      const response = await API.GET(`/Admin/applications/${id}`)
      const application = response.application

      setFormValues({
        data: {
          ...application,
          id: application.id || '',
          applicationId: application.applicationId,
          title: application.title || '',
          description: application.description || '',
          logoData: application.logoData || '',
          link: application.link || '',
          isSAML: application.isSAML,
          isDuplicate: application.isDuplicate,
        },
      })
    } catch (error) {
      console.error('Error fetching application:', error)
    }
  }

  const fetchAccessList = async () => {
    try {
      const response = await API.GET(
        `/Admin/access?applicationId=${selectedRow?.applicationId}`,
      )
      setAccessList(response.employeeAccesses)
    } catch (error) {
      console.error('Error fetching access list:', error)
    }
  }

  const fetchExclusionList = async () => {
    try {
      const response = await API.GET(
        `/Admin/exclusions?applicationId=${selectedRow?.applicationId}`,
      )
      setExclusionList(response.exclusions)
    } catch (error) {
      console.error('Error fetching exclusion list:', error)
    }
  }

  const fetchAdGroupList = async () => {
    try {
      const response = await API.GET(
        `/Admin/adgroups?applicationId=${selectedRow?.applicationId}`,
      )
      setAdGroupList(response.groups)
    } catch (error) {
      console.error('Error fetching adGroup list:', error)
    }
  }

  React.useEffect(() => {
    if (isViewAccessDialogOpen && selectedRow?.applicationId) {
      fetchApplicationById(selectedRow.applicationId)
      fetchAccessList()
      fetchExclusionList()
      fetchAdGroupList()
    }
  }, [isViewAccessDialogOpen])

  React.useEffect(() => {
    fetchApplications()
  }, [])

  const rows: GridRowsProp = [
    {
      id: 0,
      applicationId: '',
      title: '',
      description: '',
      logoData: '',
      link: '',
      isSAML: false,
    },
  ]

  const [gridRows, setGridRows] = React.useState<GridRowsProp>(rows)

  const [formValues, setFormValues] = React.useState<FormValues>({
    data: {
      id: '',
      applicationId: '',
      title: '',
      description: '',
      logoData: '',
      link: '',
      isSAML: false,
      isDuplicate: false,
    },
  })

  const handleFormSubmit = (updatedData: RowData) => {
    setGridRows((prevRows) => {
      if (updatedData.applicationId === '') {
        const newId =
          Math.max(...prevRows.map((r) => r['applicationId']), 0) + 1
        return [...prevRows, { ...updatedData, applicationId: newId }]
      } else {
        return prevRows.map((row) =>
          row['applicationId'] === updatedData.applicationId
            ? { ...updatedData, isSAML: updatedData.isSAML }
            : row,
        )
      }
    })
    fetchApplications()
  }

  const handleWarningClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') return
    setIsWarningDialogOpen(false)
  }

  const handleViewAccessClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') return
    setIsViewAccessDialogOpen(false)
    // Clear all form data
    setFormValues({
      data: {
        id: '',
        applicationId: '',
        title: '',
        description: '',
        logoData: '',
        link: '',
        isSAML: false,
        isDuplicate: false,
      },
    })
    // Clear lists
    setAccessList([])
    setExclusionList([])
    setAdGroupList([])
    setSelectedRow(null)
  }

  const renderLinks = (params: {
    row: { link: string; title: string; isSAML: boolean }
  }) => {
    return (
      <a
        href={params.row.link}
        title={params.row.link}
        target='_blank'
        rel='noreferrer'
      >
        {params.row.title}
      </a>
    )
  }

  const handleDialogDeleteOpen = (rowData: RowData) => {
    setSelectedRow(rowData)
    setIsWarningDialogOpen(true)
  }

  const handleDelete = (applicationId: string) => {
    deleteApplication(applicationId)
    fetchApplications()
  }

  const handleDialogOpen = (rowData: RowData) => {
    setSelectedRow(rowData)
    setIsDialogOpen(true)

    setFormValues({
      data: {
        ...rowData,
        isSAML: rowData.isSAML,
        isDuplicate: false,
      },
    })
  }

  const handleViewAccessDialogOpen = (
    rowData: RowData,
    accessList: AccessList[],
    exclusionList: ExclusionList[],
    adGroupList: AdGroupList[],
  ) => {
    setSelectedRow(rowData)
    setIsDialogOpen(false)
    setIsViewAccessDialogOpen(true)

    setAccessList(
      accessList?.map((user) => ({
        windowsLogin: user.windowsLogin,
        applicationId: rowData.applicationId,
        accessGranted: true,
      })) || [],
    )

    setExclusionList(
      exclusionList?.map((user) => ({
        windowsLogin: user.windowsLogin,
        applicationId: rowData.applicationId,
      })) || [],
    )

    setAdGroupList(
      adGroupList?.map((user) => ({
        name: user.name,
        applicationId: rowData.applicationId,
      })) || [],
    )
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormValues = {
      ...formValues,
      data: {
        ...formValues.data,
        [e.target.name]: e.target.value,
      },
    }
    setFormValues(newFormValues)
  }

  const addToList = async (
    type: 'access' | 'exclusion' | 'adGroup',
    value: string,
  ) => {
    if (!value) {
      return
    } // Add validation for empty value

    switch (type) {
      case 'access': {
        const newAccess: AccessList = {
          windowsLogin: value,
          applicationId: formValues.data.applicationId || '',
          accessGranted: true,
        }
        setAccessList((prevList) => [...(prevList || []), newAccess])
        setNewAccessItems((prev) => [...prev, newAccess])
        break
      }

      case 'exclusion': {
        const newExclusion: ExclusionList = {
          windowsLogin: value,
          applicationId: formValues.data.applicationId || '',
        }
        setExclusionList((prevList) => [...(prevList || []), newExclusion])
        setNewExclusionItems((prev) => [...prev, newExclusion])
        break
      }
      case 'adGroup': {
        const newAdGroup: AdGroupList = {
          name: value,
          applicationId: formValues.data.applicationId || '',
        }
        setAdGroupList((prevList) => [...(prevList || []), newAdGroup])
        setNewAdGroupItems((prev) => [...prev, newAdGroup])
        break
      }
    }
  }

  const removeFromList = async (
    index: number,
    type: 'access' | 'exclusion' | 'adGroup',
  ) => {
    switch (type) {
      case 'access': {
        const itemToRemove = accessList[index]
        const isNewItem = newAccessItems.some(
          (item) => item.windowsLogin === itemToRemove?.windowsLogin,
        )

        if (isNewItem) {
          setAccessList((prev) => prev.filter((_, i) => i !== index))
          setNewAccessItems((prev) =>
            prev.filter(
              (item) => item.windowsLogin !== itemToRemove?.windowsLogin,
            ),
          )
        } else if (itemToRemove?.applicationId) {
          try {
            await API.DELETE('/Admin/access', {
              windowsLogin: itemToRemove.windowsLogin,
              applicationId: itemToRemove.applicationId,
            })
          } catch (error) {
            console.log('Error deleting access:', error)
          } finally {
            setAccessList((prev) => prev.filter((_, i) => i !== index))
          }
        }
        break
      }

      case 'exclusion': {
        const itemToRemove = exclusionList[index]
        const isNewItem = newExclusionItems.some(
          (item) => item.windowsLogin === itemToRemove?.windowsLogin,
        )

        if (isNewItem) {
          setExclusionList((prev) => prev.filter((_, i) => i !== index))
          setNewExclusionItems((prev) =>
            prev.filter(
              (item) => item.windowsLogin !== itemToRemove?.windowsLogin,
            ),
          )
        } else if (itemToRemove?.applicationId) {
          try {
            await API.DELETE('/Admin/exclusion', {
              windowsLogin: itemToRemove.windowsLogin,
              applicationId: itemToRemove.applicationId,
            })
          } catch (error) {
            console.log('Error deleting exclusion:', error)
          } finally {
            setExclusionList((prev) => prev.filter((_, i) => i !== index))
          }
        }
        break
      }

      case 'adGroup': {
        const itemToRemove = adGroupList[index]
        const isNewItem = newAdGroupItems.some(
          (item) => item.name === itemToRemove?.name,
        )

        if (isNewItem) {
          setAdGroupList((prev) => prev.filter((_, i) => i !== index))
          setNewAdGroupItems((prev) =>
            prev.filter((item) => item.name !== itemToRemove?.name),
          )
        } else if (itemToRemove?.applicationId) {
          try {
            await API.DELETE('/Admin/adgroups', {
              groupName: itemToRemove.name,
              applicationId: itemToRemove.applicationId,
            })
          } catch (error) {
            console.log('Error deleting adgroup:', error)
          } finally {
            setAdGroupList((prev) => prev.filter((_, i) => i !== index))
          }
        }
        break
      }
    }
  }
  const renderDetailsButton = (params: { row: RowData }) => {
    return (
      <strong>
        <AdminButton
          variant='outlined'
          color='success'
          size='small'
          onClick={() => handleDialogOpen(params.row as RowData)}
        >
          Edit
        </AdminButton>
        <AdminButton
          variant='outlined'
          color='success'
          size='small'
          onClick={() => handleDialogDeleteOpen(params.row as RowData)}
        >
          Delete
        </AdminButton>
        <AdminButton
          variant='outlined'
          color='success'
          size='small'
          onClick={() =>
            handleViewAccessDialogOpen(
              params.row as RowData,
              accessList,
              exclusionList,
              adGroupList,
            )
          }
        >
          View Access
        </AdminButton>
      </strong>
    )
  }

  const renderLogo = (params: { row: { logoData: string } }) => {
    const publicUrl = process.env.PUBLIC_URL || ''
    const logoData = params.row.logoData

    return <img src={`${publicUrl}${logoData}`} alt={logoData} width='30' />
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      resizable: false,
      hideable: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 150,
      resizable: false,
      headerClassName: 'admin-grid-header',
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      minWidth: 250,
      resizable: false,
      headerClassName: 'admin-grid-header',
    },
    {
      field: 'logoData',
      headerName: 'Logo',
      width: 115,
      resizable: false,
      headerClassName: 'admin-grid-header',
      renderCell: renderLogo,
      display: 'flex',
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 250,
      resizable: false,
      headerClassName: 'admin-grid-header',
      renderCell: renderLinks,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 310,
      resizable: false,
      headerClassName: 'admin-grid-header',
      renderCell: renderDetailsButton,
    },
  ]

  return (
    <>
      <React.Fragment>
        <h3>Application List</h3>
        <div
          style={{
            height: 600,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <DataGrid
            loading={loading}
            disableRowSelectionOnClick
            rows={gridRows}
            columns={columns}
            getRowId={(row) => row.applicationId} // Add this line
            sx={gridCellStyle}
            initialState={{
              sorting: {
                sortModel: [{ field: 'id', sort: 'desc' }],
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
          <CreateEditApplication
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            formValues={formValues}
            rowData={selectedRow}
            onSubmit={handleFormSubmit}
          />
        </div>
      </React.Fragment>

      <DialogViewAccess
        isViewAccessDialogOpen={isViewAccessDialogOpen}
        setIsViewAccessDialogOpen={setIsViewAccessDialogOpen}
        formValues={formValues}
        formFields={formFields}
        accessList={accessList}
        exclusionList={exclusionList}
        adGroupList={adGroupList}
        onClose={handleViewAccessClose}
        addToList={addToList}
        removeFromList={removeFromList}
        handleInputChange={handleInputChange}
        rowData={selectedRow}
        data={selectedRow}
        newAccessItems={newAccessItems}
        newExclusionItems={newExclusionItems}
        newAdGroupItems={newAdGroupItems}
        onSave={(_formValues: FormValues, _isDuplicate: boolean) => {
        // Clear all data
        setFormValues({
          data: {
            id: '',
            applicationId: '',
            title: '',
            description: '',
            logoData: '',
            link: '',
            isSAML: false,
            isDuplicate: false,
          },
        })
          setIsViewAccessDialogOpen(false)
        }}
      />

      <DialogWarning
        open={isWarningDialogOpen}
        onClose={handleWarningClose}
        onDelete={handleDelete}
        data={selectedRow}
        isDelete={true}
        dialogContent={
          <div>
            You are about to delete the <strong>{selectedRow?.title}</strong>{' '}
            application, are you sure you want to continue?
          </div>
        }
      />
    </>
  )
}

export default ApplicationList
