import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'

import {
  handleDuplicateError,
  getDuplicateMessage,
  getUserNotFoundMessage,
} from '../utils/ErrorMessages'
import DialogWarning from './DialogWarning'
import { API } from '../services/endpoints'
import UserAccess from './UserAccess'
import {
  FormValues,
  RowData,
  AccessList,
  ExclusionList,
  AdGroupList,
} from '../definitions/gridDef'
import { AdminButton } from '../components/Styled/Administration'

interface ViewAccessProps {
  formFields: any[]
  formValues: FormValues
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  addToList: (type: 'access' | 'exclusion' | 'adGroup', value: string) => void
  removeFromList: (
    index: number,
    type: 'access' | 'exclusion' | 'adGroup',
  ) => void
  setIsViewAccessDialogOpen: (isOpen: boolean) => void
  isViewAccessDialogOpen: boolean
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  disabled?: boolean
  rowData: RowData | null
  onSave?: (formValues: FormValues, isDuplicate: boolean) => void
  data: RowData | null
  newAccessItems: AccessList[]
  newExclusionItems: ExclusionList[]
  newAdGroupItems: AdGroupList[]
  accessList: AccessList[]
  exclusionList: ExclusionList[]
  adGroupList: AdGroupList[]
}

const ViewAccess: React.FC<ViewAccessProps> = ({
  formFields,
  formValues,
  accessList,
  exclusionList,
  adGroupList,
  handleInputChange,
  addToList,
  removeFromList,
  setIsViewAccessDialogOpen,
  onSave,
  isViewAccessDialogOpen,
  onClose,
  data,
  newAccessItems,
  newExclusionItems,
  newAdGroupItems,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const [isWarningDialogOpen, setIsWarningDialogOpen] = React.useState(false)
  const [isWarningMessage, setIsWarningMessage] = React.useState('')
  const [isDuplicate, setIsDuplicate] = React.useState(false)

  const handleWarningClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') return
    setIsWarningDialogOpen(false)
  }

  // Modify postAccessList to check duplicates before posting
  const postAccessList = async (data: AccessList) => {
    try {
      const response = await API.POST('/Admin/access', data)
      return response.data
    } catch (error: any) {
      if (error.response?.status === 409) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getDuplicateMessage.accessList(data.windowsLogin),
        )
      } else if (
        error.response?.status === 400 ||
        error.response?.status === 404
      ) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getUserNotFoundMessage.accessList(data.windowsLogin),
        )
      } else {
        setIsWarningMessage('An unexpected error occurred. Please try again.')
        setIsWarningDialogOpen(true)
      }

      console.error('Error saving access:', error)
      throw error
    }
  }

  const postExclusionList = async (data: ExclusionList) => {
    try {
      const response = await API.POST('/Admin/exclusion', data)
      return response.data
    } catch (error: any) {
      if (error.response?.status === 409) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getDuplicateMessage.exclusionList(data.windowsLogin),
        )
      } else if (
        error.response?.status === 400 ||
        error.response?.status === 404
      ) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getUserNotFoundMessage.exclusionList(data.windowsLogin),
        )
      } else {
        setIsWarningMessage('An unexpected error occurred. Please try again.')
        setIsWarningDialogOpen(true)
      }

      console.error('Error saving access:', error)
      throw error
    }
  }

  const postAdGroupList = async (data: AdGroupList) => {
    try {
      const response = await API.POST('/Admin/adgroups', data)
      return response.data
    } catch (error: any) {
      if (error.response?.status === 409 && data.groupName) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getDuplicateMessage.adGroupList(data.groupName),
        )
      } else if (
        (error.response?.status === 400 && data.groupName) ||
        (error.response?.status === 404 && data.groupName)
      ) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getUserNotFoundMessage.adGroupList(data.groupName),
        )
      } else {
        setIsWarningMessage('An unexpected error occurred. Please try again.')
        setIsWarningDialogOpen(true)
      }

      console.error('Error saving access:', error)
      throw error
    }
  }

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!data) return

    try {
      const applicationId = data.applicationId

      // Create batch requests for each list type
      const accessPromises = newAccessItems.map((item) =>
        postAccessList({
          windowsLogin: item.windowsLogin,
          applicationId: applicationId,
          accessGranted: true,
        }),
      )

      const exclusionPromises = newExclusionItems.map((item) =>
        postExclusionList({
          windowsLogin: item.windowsLogin,
          applicationId: applicationId,
        }),
      )

      const adGroupPromises = newAdGroupItems.map((item) =>
        postAdGroupList({
          groupName: item.name,
          applicationId: applicationId,
        }),
      )

      // Wait for all requests to complete
      await Promise.all([
        ...accessPromises,
        ...exclusionPromises,
        ...adGroupPromises,
      ])

      if (onSave && formValues) {
        onSave(formValues, isDuplicate)
        setIsDuplicate(false)
        handleClose({}, 'escapeKeyDown')
      }
    } catch (error) {
      console.error('Failed to save:', error)
    }
  }

  const handleClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') return
    setIsViewAccessDialogOpen(false)
  }

  const hasRequiredData = (data: any) => {
    return (
      data.title?.trim() &&
      data.description?.trim() &&
      data.link?.trim() &&
      data.logoData?.trim() &&
      (accessList?.length > 0 || adGroupList?.length > 0)
    )
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        sx={{
          margin: { xs: '10px', sm: '113px' },
          '& .MuiPaper-root': { borderRadius: '8px', height: { xs: 'auto' } },
        }}
        open={isViewAccessDialogOpen}
        onClose={handleClose}
      >
        <DialogWarning
          open={isWarningDialogOpen}
          onClose={handleWarningClose}
          data={data}
          isDelete={false}
          dialogContent={isWarningMessage}
        />
        <form onSubmit={handleSave}>
          <DialogTitle>
            Application Access ({formValues.data?.title})
          </DialogTitle>
          <DialogContent dividers>
            <UserAccess
              formFields={formFields}
              formValues={formValues}
              accessList={accessList}
              exclusionList={exclusionList}
              adGroupList={adGroupList}
              handleInputChange={handleInputChange}
              addToList={addToList}
              removeFromList={removeFromList}
            />
          </DialogContent>
          <DialogActions>
            <AdminButton
              variant='contained'
              color='success'
              type='submit'
              disabled={!hasRequiredData(formValues.data)}
            >
              Save
            </AdminButton>
            <AdminButton
              variant='contained'
              color='success'
              onClick={(event) => onClose(event, 'escapeKeyDown')}
            >
              Cancel
            </AdminButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
export default ViewAccess
