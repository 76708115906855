/**
 * The getApiBaseUrl function is used to determine the base URL for the API.
 * If the application is running on localhost, then the API is running on GCP.
 * If the application is running on a production server, then the API is running on the same server.
 * @returns {string} The base URL for the API
 */
export const getApiBaseUrl = () => {
  const host = window.location.origin
  const url = process.env['REACT_APP_DEV_BASEURL']
  // const url = 'http://localhost:8065/api'
  return /localhost/i.test(host) ? url : `${window.location.origin}/api`
}
