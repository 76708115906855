import { ErrorBoundary } from 'react-error-boundary'

import { ErrorFallback } from './pages/ErrorFallback'
import { Router } from './routes/Router'

const logError = (error: Error, info: { componentStack: string }) => {
  console.error(error, info)
}

export const App: React.FC<React.PropsWithChildren> = () => {
  return (
    <div className='App' data-testid='App'>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <Router />
      </ErrorBoundary>
    </div>
  )
}

export default App
