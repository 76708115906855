import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { DialogTitle } from '@mui/material'

import { AdminButton } from '../components/Styled/Administration'
import { RowData } from '../definitions/gridDef'

interface DialogDefaultProps {
  open: boolean
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  onDelete?: (applicationId: string) => void
  isDelete?: boolean
  dialogContent: React.ReactNode
  data: RowData | null
}
const DialogDefault: React.FC<DialogDefaultProps> = ({
  open,
  onClose,
  onDelete,
  data,
  isDelete,
  dialogContent,
}) => {
  const handleDelete = () => {
    if (onDelete && data && data.applicationId) {
      onDelete(data.applicationId)
      onClose({}, 'escapeKeyDown')
    }
  }

  const handleClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') return
    onClose({}, 'escapeKeyDown')
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': { borderRadius: '8px' },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle color='error'>Warning</DialogTitle>
      <DialogContent dividers>{dialogContent}</DialogContent>
      <DialogActions>
        {isDelete && (
          <>
            <AdminButton
              variant='outlined'
              color='success'
              type='submit'
              onClick={(event) => onClose(event, 'escapeKeyDown')}
            >
              Cancel
            </AdminButton>
            <AdminButton
              variant='contained'
              color='error'
              type='submit'
              onClick={handleDelete}
            >
              Delete
            </AdminButton>
          </>
        )}
        {!isDelete && (
          <AdminButton
            variant='contained'
            color='success'
            type='submit'
            onClick={(event) => onClose(event, 'escapeKeyDown')}
          >
            OK
          </AdminButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
export default DialogDefault
