import axios from 'axios'

import { oktaAuth } from '../auth/Authentication'
import { getApiBaseUrl } from '../api/utils/getApiBaseUrl'

const getAuthHeaders = async () => {
  const token = await oktaAuth.tokenManager
    .getTokens()
    .then((tokens) => tokens.accessToken?.accessToken)

  return {
    Authorization: `Bearer ${token}`,
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    'x-api-version': '1.0',
  }
}

export const API = {
  POST: async (url: string, data: any, config: { headers?: any } = {}) => {
    try {
      const headers = await getAuthHeaders()
      const response = await axios.post(`${getApiBaseUrl()}${url}`, data, {
        ...config,
        headers: { ...headers, ...config.headers },
      })
      return response.data
    } catch (error: any) {
      if (error.response?.status === 401) {
        const headers = await getAuthHeaders()
        const response = await axios.post(`${getApiBaseUrl()}${url}`, data, {
          ...config,
          headers: { ...headers, ...config.headers },
        })
        return response.data
      }
      throw error
    }
  },
  PUT: async (url: string, data: any, config: { headers?: any } = {}) => {
    try {
      const headers = await getAuthHeaders()
      const response = await axios.put(`${getApiBaseUrl()}${url}`, data, {
        ...config,
        headers: { ...headers, ...config.headers },
      })
      return response.data
    } catch (error: any) {
      if (error.response?.status === 401) {
        const headers = await getAuthHeaders()
        const response = await axios.put(`${getApiBaseUrl()}${url}`, data, {
          ...config,
          headers: { ...headers, ...config.headers },
        })
        return response.data
      }
      throw error
    }
  },
  GET: async (url: string, config: { headers?: any } = {}) => {
    try {
      const headers = await getAuthHeaders()
      const response = await axios.get(`${getApiBaseUrl()}${url}`, {
        ...config,
        headers: { ...headers, ...config.headers },
      })
      return response.data
    } catch (error: any) {
      if (error.response?.status === 401) {
        const headers = await getAuthHeaders()
        const response = await axios.get(`${getApiBaseUrl()}${url}`, {
          ...config,
          headers: { ...headers, ...config.headers },
        })
        return response.data
      }
      throw error
    }
  },
  DELETE: async (url: string, data?: any, config: { headers?: any } = {}) => {
    try {
      const headers = await getAuthHeaders()
      const response = await axios.delete(`${getApiBaseUrl()}${url}`, {
        ...config,
        headers: { ...headers, ...config.headers },
        data,
      })
      return response.data
    } catch (error: any) {
      if (error.response?.status === 401) {
        const headers = await getAuthHeaders()
        const response = await axios.delete(`${getApiBaseUrl()}${url}`, {
          ...config,
          headers: { ...headers, ...config.headers },
          data,
        })
        return response.data
      }
      throw error
    }
  },
}
