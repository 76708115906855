import { useNavigate } from 'react-router-dom'

import {
  AdminBox,
  AdminBoxHeader,
  AdminBoxBody,
  AdminBoxHeaderLogos,
  AdminCard,
  AdminButton,
} from '../components/Styled/Administration'
import ApplicationList from '../components/ApplicationList'
import { ReactComponent as EmployeePortalLogoGreen } from '../assets/EmployeePortalLogoGreen.svg'
import { ReactComponent as Bet365LogoBlack } from '../assets/Bet365LogoBlack.svg'

export const Administration: React.FC = () => {
  const navigate = useNavigate()
  return (
    <>
      <AdminBox>
        <AdminBoxHeader>
          <AdminButton color='success' onClick={() => navigate('/')}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              <path
                d='M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z'
                fill='#2e7d32'
              />
            </svg>
            <span>Back</span>
          </AdminButton>
          <AdminBoxHeaderLogos>
            <EmployeePortalLogoGreen />
            <h3>Apps Management</h3>
          </AdminBoxHeaderLogos>
          <Bet365LogoBlack />
        </AdminBoxHeader>
        <AdminBoxBody>
          <AdminCard>
            <ApplicationList />
          </AdminCard>
        </AdminBoxBody>
      </AdminBox>
    </>
  )
}
export default Administration
