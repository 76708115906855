import styled from 'styled-components'
import { Box, Button, Card } from '@mui/material'

export const AdminButton = styled(Button)`
  && {
    font-family: var(--poppins-reg);
    border-radius: 16px;
    text-transform: none;
    gap: 8px;
    padding: 4px 16px;
    font-weight: 400;
    cursor: pointer;
    align-self: center;
    margin: 0 4px;

    svg {
      width: 14px;
    }
  }
`

export const AdminBox = styled(Box)`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const AdminBoxHeader = styled(Box)`
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  min-height: 80px;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 8px;
  }
`

export const AdminBoxHeaderLogos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AdminBoxBody = styled(Box)`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const AdminCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  min-width: 90vw;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  gap: 20px;
`

// Or use the attrs method:
export const VisuallyHiddenInput = styled.input.attrs({ type: 'file' })`
  color: transparent;
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
`

export const InputBoxColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 10px;
  justify-content: space-evenly;
  div {
    flex: 1;
    min-width: 0;
    .input-box-column {
      display: flex;
      margin: 3px 0;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`

export const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #333333;
`

export const ListContainer = styled.div`
  margin-top: 5px;
  height: 170px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 5px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    background-color: rgb(249, 249, 249);
    margin: 5px 0px;
    padding-left: 10px;
    border-radius: 3px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    font-family: var(--poppins-reg);
    font-weight: 400;
    font-size: 12px;
  }
`
