import * as React from 'react'
import { TextField } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { API } from '../services/endpoints'
import UserAccess from './UserAccess'
import {
  FormValues,
  formFields,
  RowData,
  AccessList,
  ExclusionList,
  AdGroupList,
} from '../definitions/gridDef'
import Dialog from './Dialog'
import DialogWarning from './DialogWarning'
import {
  AdminButton,
  Label,
  VisuallyHiddenInput,
  InputBox,
} from '../components/Styled/Administration'

interface CreateEditApplicationProps {
  isDialogOpen: boolean
  setIsDialogOpen: (isOpen: boolean) => void
  formValues: FormValues
  rowData: RowData | null
  onSubmit: (data: RowData) => void
}

export const CreateEditApplication: React.FC<CreateEditApplicationProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  formValues: initialFormValues,
  // rowData,
  onSubmit,
}) => {
  const [samlChecked, setSamlChecked] = React.useState(false)
  const [accessList, setAccessList] = React.useState<AccessList[]>([])
  const [exclusionList, setExclusionList] = React.useState<ExclusionList[]>([])
  const [adGroupList, setAdGroupList] = React.useState<AdGroupList[]>([])
  const [errorImage, setErrorImage] = React.useState(false)
  const [errorUrl, setErrorUrl] = React.useState(false)
  const [helperTextImage, setHelperTextImage] = React.useState('')
  const [helperTextUrl, setHelperTextUrl] = React.useState('')
  const [cursorPosition, setCursorPosition] = React.useState<number | null>(
    null,
  )
  // Add these new state variables to track newly added items
  const [newAccessItems, setNewAccessItems] = React.useState<AccessList[]>([])
  const [newExclusionItems, setNewExclusionItems] = React.useState<
    ExclusionList[]
  >([])
  const [newAdGroupItems, setNewAdGroupItems] = React.useState<AdGroupList[]>(
    [],
  )
  const inputRef = React.useRef<HTMLInputElement>(null)

  const fetchApplicationById = async (id: string) => {
    try {
      const response = await API.GET(`/Admin/applications/${id}`)
      const application = response.application

      setFormValues({
        data: {
          ...application,
          id: application.id || '',
          applicationId: application.applicationId,
          title: application.title || '',
          description: application.description || '',
          logoData: application.logoData || '',
          link: application.link || '',
          isSAML: application.isSAML,
        },
      })

      setSamlChecked(application.isSAML)
    } catch (error) {
      console.error('Error fetching application:', error)
    }
  }

  const addToList = async (
    type: 'access' | 'exclusion' | 'adGroup',
    value: string,
  ) => {
    if (!value) return // Add validation for empty value

    switch (type) {
      case 'access': {
        const newAccess: AccessList = {
          windowsLogin: value,
          applicationId: formValues.data.applicationId || '',
          accessGranted: true,
        }
        setAccessList((prevList) => [...(prevList || []), newAccess])
        setNewAccessItems((prev) => [...prev, newAccess])
        break
      }

      case 'exclusion': {
        const newExclusion: ExclusionList = {
          windowsLogin: value,
          applicationId: formValues.data.applicationId || '',
        }
        setExclusionList((prevList) => [...(prevList || []), newExclusion])
        setNewExclusionItems((prev) => [...prev, newExclusion])
        break
      }
      case 'adGroup': {
        const newAdGroup: AdGroupList = {
          name: value,
          applicationId: formValues.data.applicationId || '',
        }
        setAdGroupList((prevList) => [...(prevList || []), newAdGroup])
        setNewAdGroupItems((prev) => [...prev, newAdGroup])
        break
      }
    }
  }

  const removeFromList = async (
    index: number,
    type: 'access' | 'exclusion' | 'adGroup',
  ) => {
    switch (type) {
      case 'access': {
        const itemToRemove = accessList[index]
        const isNewItem = newAccessItems.some(
          (item) => item.windowsLogin === itemToRemove?.windowsLogin,
        )

        if (isNewItem) {
          setAccessList((prev) => prev.filter((_, i) => i !== index))
          setNewAccessItems((prev) =>
            prev.filter(
              (item) => item.windowsLogin !== itemToRemove?.windowsLogin,
            ),
          )
        } else if (itemToRemove?.applicationId) {
          try {
            await API.DELETE('/Admin/access', {
              windowsLogin: itemToRemove.windowsLogin,
              applicationId: itemToRemove.applicationId,
            })
          } catch (error) {
            console.log('Error deleting access:', error)
          } finally {
            setAccessList((prev) => prev.filter((_, i) => i !== index))
          }
        }
        break
      }

      case 'exclusion': {
        const itemToRemove = exclusionList[index]
        const isNewItem = newExclusionItems.some(
          (item) => item.windowsLogin === itemToRemove?.windowsLogin,
        )

        if (isNewItem) {
          setExclusionList((prev) => prev.filter((_, i) => i !== index))
          setNewExclusionItems((prev) =>
            prev.filter(
              (item) => item.windowsLogin !== itemToRemove?.windowsLogin,
            ),
          )
        } else if (itemToRemove?.applicationId) {
          try {
            await API.DELETE('/Admin/exclusion', {
              windowsLogin: itemToRemove.windowsLogin,
              applicationId: itemToRemove.applicationId,
            })
          } catch (error) {
            console.log('Error deleting exclusion:', error)
          } finally {
            setExclusionList((prev) => prev.filter((_, i) => i !== index))
          }
        }
        break
      }

      case 'adGroup': {
        const itemToRemove = adGroupList[index]
        const isNewItem = newAdGroupItems.some(
          (item) => item.name === itemToRemove?.name,
        )

        if (isNewItem) {
          setAdGroupList((prev) => prev.filter((_, i) => i !== index))
          setNewAdGroupItems((prev) =>
            prev.filter((item) => item.name !== itemToRemove?.name),
          )
        } else if (itemToRemove?.applicationId) {
          try {
            await API.DELETE('/Admin/adgroups', {
              groupName: itemToRemove.name,
              applicationId: itemToRemove.applicationId,
            })
          } catch (error) {
            console.log('Error deleting adgroup:', error)
          } finally {
            setAdGroupList((prev) => prev.filter((_, i) => i !== index))
          }
        }
        break
      }
    }
  }

  // Method to handle closing the dialog
  const handleClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') return
    setIsDialogOpen(false)
    setErrorImage(false)
    setErrorUrl(false)
    setHelperTextImage('')
    setHelperTextUrl('')
    // reset id for create new application
    initialFormValues.data.applicationId = ''
  }

  const handleWarningClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') return
    setIsWarningDialogOpen(false)
  }

  const [isWarningDialogOpen, setIsWarningDialogOpen] = React.useState(false)

  const [formValues, setFormValues] = React.useState<FormValues>({
    data: {
      id: '',
      applicationId: '',
      title: '',
      description: '',
      logoData: '',
      link: '',
      isSAML: false,
      isDuplicate: false,
    },
  })

  React.useEffect(() => {
    if (isDialogOpen && initialFormValues.data.applicationId) {
      fetchApplicationById(initialFormValues.data.applicationId)
      const id = initialFormValues.data.applicationId

      Promise.all([
        API.GET(`/Admin/applications/${id}`),
        API.GET(`/Admin/access?applicationId=${id}`),
        API.GET(`/Admin/exclusions?applicationId=${id}`),
        API.GET(`/Admin/adgroups?applicationId=${id}`),
      ]).then(([appData, accessData, exclusionData, groupData]) => {
        setFormValues({
          data: {
            ...appData.application,
          },
        })
        if (!initialFormValues.data.applicationId) {
          setFormValues({
            data: {
              id: '',
              applicationId: '',
              title: '',
              description: '',
              logoData: '',
              link: '',
              isSAML: false,
              isDuplicate: false,
            },
          })
          setAccessList([])
          setExclusionList([])
          setAdGroupList([])
          setSamlChecked(false)
        } else {
          setFormValues({
            data: {
              ...appData.application,
            },
          })
          setAccessList(accessData.employeeAccesses)
          setExclusionList(exclusionData.exclusions)
          setAdGroupList(groupData.groups)
          setSamlChecked(appData.application.isSAML)
        }
      })
    } else {
      // Reset SAML state when opening dialog for new application
      setSamlChecked(false)
    }
  }, [isDialogOpen, initialFormValues.data.applicationId])
  const handleSamlUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setSamlChecked(isChecked)
    setFormValues({
      ...formValues,
      data: {
        ...formValues.data,
        isSAML: isChecked,
        link: isChecked
          ? 'https://portal.hillsidenewmedia.com/'
          : formValues.data.link,
      },
    })
  }
  const renderLogo = (logoData: string) => {
    const publicUrl = process.env.PUBLIC_URL || ''

    return <img src={`${publicUrl}${logoData}`} alt={logoData} width='30' />
  }

  const handleTitle = () => {
    return formValues.data.applicationId
      ? 'Edit Application'
      : 'Create New Application'
  }

  const hasRequiredData = (data: any) => {
    return (
      data.title?.trim() &&
      data.description?.trim() &&
      data.link?.trim() &&
      data.logoData?.trim() &&
      (accessList?.length > 0 || adGroupList?.length > 0) &&
      !errorUrl
    )
  }

  const handleClear = () => {
    setFormValues({
      data: {
        id: '',
        applicationId: '',
        title: '',
        description: '',
        logoData: '',
        link: '',
        isSAML: false,
        isDuplicate: false,
      },
    })
    setAccessList([])
    setIsDialogOpen(false)
  }

  const handleClickCreateNew = () => {
    handleClear()
    const newData = {
      id: '',
      applicationId: '',
      title: '',
      description: '',
      logoData: '',
      link: '',
      isSAML: false,
      isDuplicate: false,
    }
    setFormValues({
      data: newData,
    })
    setAccessList([])
    setExclusionList([])
    setAdGroupList([])
    setIsDialogOpen(true)
  }

  const validateUrl = (url: string): Promise<boolean> => {
    return new Promise((resolve) => {
      try {
        const urlObject = new URL(url)
        const isValid =
          urlObject.protocol === 'http:' || urlObject.protocol === 'https:'
        resolve(isValid)
      } catch {
        resolve(false)
      }
    })
  }

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, selectionStart } = e.target
    setCursorPosition(selectionStart)

    if (name === 'link') {
      const isValidUrl = await validateUrl(value)
      if (!isValidUrl && value !== '') {
        setErrorUrl(true)
        setHelperTextUrl(
          'Please enter a valid URL starting with http:// or https://',
        )
      } else {
        setErrorUrl(false)
        setHelperTextUrl('')
      }
    }

    const newFormValues = {
      ...formValues,
      data: {
        ...formValues.data,
        [name]: value,
      },
    }
    setFormValues(newFormValues)
  }

  // Add this useEffect to monitor formValues changes
  React.useEffect(() => {
    console.log('formValues updated:', formValues)
  }, [formValues])

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files || files.length === 0) return

    const selectedFile = files[0]
    if (!selectedFile) return

    const reader = new FileReader()

    reader.onloadend = async () => {
      const base64String = reader.result as string
      let formattedBase64 = base64String

      // For SVG files, keep the raw SVG content
      if (selectedFile.type === 'image/svg+xml') {
        formattedBase64 = `data:image/svg+xml,${encodeURIComponent(
          base64String,
        )}`
      }

      const validTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/svg+xml',
      ]
      if (!selectedFile.type || !validTypes.includes(selectedFile.type)) {
        setErrorImage(true)
        setHelperTextImage(
          'Please upload a valid image file (JPG, JPEG, PNG or SVG).',
        )
        return
      }

      if (
        ['image/jpeg', 'image/jpg', 'image/png'].includes(selectedFile.type)
      ) {
        const img = new Image()
        img.src = base64String
        await new Promise((resolve) => {
          img.onload = () => {
            if (img.width !== 60 || img.height !== 60) {
              setErrorImage(true)
              setHelperTextImage(
                'JPG, JPEG & PNG image dimensions must be 60x60 pixels. Alternatively upload a SVG file.',
              )
              resolve(null)
            } else {
              setErrorImage(false)
              setHelperTextImage('')
              setFormValues((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  logoData: formattedBase64,
                },
              }))
              resolve(null)
            }
          }
        })
        return
      }

      setErrorImage(false)
      setHelperTextImage('')
      setFormValues((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          logoData: formattedBase64,
        },
      }))
    }

    // Use different reader method based on file type
    if (selectedFile.type === 'image/svg+xml') {
      reader.readAsText(selectedFile)
    } else {
      reader.readAsDataURL(selectedFile)
    }
  }

  // Add useEffect to handle cursor position
  React.useEffect(() => {
    if (cursorPosition !== null && inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
    }
  }, [formValues, cursorPosition])

  const handleClearForm = () => {
    setFormValues({
      data: {
        id: '',
        applicationId: '',
        title: '',
        description: '',
        logoData: '',
        link: '',
        isSAML: false,
        isDuplicate: false,
      },
    })
    setAccessList([])
    setIsDialogOpen(false)
  }

  const samlCheck = (params: { row: { link: string; title: string } }) => {
    if (params.row.link.includes('bet365group.okta.com') && !samlChecked) {
      return true
    }
    return false
  }

  const handleSubmit = (isDuplicate: boolean, formValues: FormValues) => {
    if (samlCheck({ row: formValues.data })) {
      setIsWarningDialogOpen(true)
      return
    }

    if (!hasRequiredData(formValues.data) || errorImage || errorUrl) return

    const submissionData = {
      ...formValues.data,
      isDuplicate: isDuplicate,
    }

    onSubmit(submissionData)
    setIsDialogOpen(isDuplicate)
    setNewAccessItems([])
    setNewExclusionItems([])
    setNewAdGroupItems([])
  }

  React.useEffect(() => {
    if (initialFormValues?.data) {
      if (accessList?.length > 0) {
        setAccessList(
          accessList.map((access) => ({
            windowsLogin: access.windowsLogin,
            applicationId: initialFormValues.data.applicationId,
            accessGranted: true,
          })),
        )
      }

      if (exclusionList?.length > 0) {
        setExclusionList(
          exclusionList.map((exclusion) => ({
            windowsLogin: exclusion.windowsLogin,
            applicationId: initialFormValues.data.applicationId,
          })),
        )
      }

      if (adGroupList?.length > 0) {
        setAdGroupList(
          adGroupList.map((group) => ({
            name: group.name,
            applicationId: initialFormValues.data.applicationId,
          })),
        )
      }
    }
  }, [initialFormValues])

  return (
    <>
      <AdminButton
        onClick={handleClickCreateNew}
        variant='contained'
        color='success'
      >
        <span>Create New Application</span>
      </AdminButton>

      <DialogWarning
        open={isWarningDialogOpen}
        onClose={handleWarningClose}
        data={formValues.data}
        dialogContent={
          <div>
            You have entered a <strong>bet365group.okta.com</strong> URL for the{' '}
            <strong>{formValues.data?.title}</strong> application, please{' '}
            <strong>Edit</strong> and select <strong>YES</strong> for a{' '}
            <strong>SAML</strong> URL.
          </div>
        }
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        onClear={handleClearForm}
        disabled={!hasRequiredData(formValues.data)}
        onSave={(formValues, isDuplicate) =>
          handleSubmit(isDuplicate, formValues)
        }
        data={formValues.data}
        formValues={formValues}
        newAccessItems={newAccessItems}
        newExclusionItems={newExclusionItems}
        newAdGroupItems={newAdGroupItems}
        accessList={accessList}
        exclusionList={exclusionList}
        adGroupList={adGroupList}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(false, formValues)
          }}
        >
          <DialogTitle>{handleTitle()}</DialogTitle>
          <DialogContent dividers>
            {/* title Field */}
            {formFields
              .filter((field) => field.name === 'Title')
              .map((field) => (
                <InputBox key={field.id}>
                  <Label>{field.label}</Label>
                  <TextField
                    color='success'
                    autoFocus
                    required
                    id={field.id}
                    name='title'
                    type='text'
                    size='small'
                    value={formValues.data?.title ?? ''}
                    onChange={handleInputChange}
                    placeholder={field.placeholder}
                  />
                </InputBox>
              ))}

            {/* description Field */}
            {formFields
              .filter((field) => field.name === 'Description')
              .map((field) => (
                <InputBox key={field.id}>
                  <Label>{field.label}</Label>
                  <TextField
                    color='success'
                    required
                    id={field.id}
                    name='description'
                    type='text'
                    multiline={true}
                    size='small'
                    value={formValues.data?.description ?? ''}
                    onChange={handleInputChange}
                    placeholder={field.placeholder}
                  />
                </InputBox>
              ))}
            {/* Logo Field (File Upload) */}
            <div key={formFields.find((field) => field.name === 'Logo')?.id}>
              {formFields
                .filter((field) => field.name === 'Logo')
                .map((field) => (
                  <InputBox key={field.id}>
                    <Label>{field.label}</Label>
                    <TextField
                      error={errorImage}
                      helperText={helperTextImage}
                      disabled
                      variant='outlined'
                      color='success'
                      id={field.id}
                      type='text'
                      fullWidth
                      size='small'
                      InputProps={{
                        startAdornment: (
                          <>
                            <VisuallyHiddenInput
                              type='file'
                              accept='image/jpeg,image/jpg,image/png,image/svg+xml'
                              onChange={handleFileChange}
                              id='file-upload'
                              style={{ width: '100px' }}
                            />
                            {renderLogo(formValues.data?.logoData ?? '')}
                          </>
                        ),
                      }}
                    />
                  </InputBox>
                ))}{' '}
            </div>
            {/* URL Field */}
            {formFields
              .filter((field) => field.name === 'Link')
              .map((field) => (
                <InputBox key={field.id}>
                  <Label>{field.label}</Label>
                  <TextField
                    inputRef={inputRef}
                    error={errorUrl}
                    helperText={helperTextUrl}
                    color='success'
                    required
                    id={field.id}
                    name='link'
                    type='text'
                    size='small'
                    value={formValues.data?.link ?? ''}
                    onChange={handleInputChange}
                    placeholder={field.placeholder}
                    InputProps={{
                      endAdornment: (
                        <FormGroup>
                          <FormControlLabel
                            sx={{
                              flexDirection: 'row-reverse',
                              marginRight: '0',
                              '@media (max-width: 768px)': {
                                flexWrap: 'wrap',
                              },
                            }}
                            control={
                              <Switch
                                color='success'
                                checked={samlChecked}
                                onChange={handleSamlUrlChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label='SAML?'
                          />
                        </FormGroup>
                      ),
                    }}
                  />
                </InputBox>
              ))}
            <UserAccess
              formFields={formFields}
              formValues={formValues}
              accessList={accessList}
              exclusionList={exclusionList}
              adGroupList={adGroupList}
              handleInputChange={handleInputChange}
              addToList={addToList}
              removeFromList={removeFromList}
            />
          </DialogContent>
        </form>
      </Dialog>
    </>
  )
}
export default CreateEditApplication
