import { useOktaAuth } from '@okta/okta-react'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { CustomUserClaim } from '@okta/okta-auth-js'

import { ADMIN_GROUPS } from '../Types/Application'

export const ProtectedAdminRoute = ({
  children,
}: {
  children: JSX.Element
}) => {
  const { oktaAuth, authState } = useOktaAuth()
  const [userRoles, setUserRoles] = useState<CustomUserClaim[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        setUserRoles((info['portal_roles'] as CustomUserClaim[]) || [])
        setLoading(false)
      })
    }
  }, [authState, oktaAuth])

  if (loading) {
    return null
  }

  const isAdmin = userRoles.some((roleClaim) =>
    ADMIN_GROUPS.some((group) => roleClaim.toString().includes(group)),
  )

  return isAdmin ? children : <Navigate to='/' replace />
}
