import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
  handleDuplicateError,
  getDuplicateMessage,
  getUserNotFoundMessage,
} from '../utils/ErrorMessages'
import DialogWarning from './DialogWarning'
import { API } from '../services/endpoints'
import {
  RowData,
  FormValues,
  AccessList,
  ExclusionList,
  AdGroupList,
} from '../definitions/gridDef'
import { AdminButton } from '../components/Styled/Administration'

interface DialogDefaultProps {
  open: boolean
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  children?: React.ReactNode
  onSubmit?: (formData: FormData) => void
  disabled?: boolean
  onSave?: (formValues: FormValues, isDuplicate: boolean) => void
  onClear?: () => void
  data: RowData | null
  formValues?: FormValues
  newAccessItems: AccessList[]
  newExclusionItems: ExclusionList[]
  newAdGroupItems: AdGroupList[]
  accessList: AccessList[]
  exclusionList: ExclusionList[]
  adGroupList: AdGroupList[]
}

const DialogDefault: React.FC<DialogDefaultProps> = ({
  open,
  onClose,
  children,
  onSave,
  newAccessItems,
  newExclusionItems,
  newAdGroupItems,
  data,
  disabled,
  formValues,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const [isWarningDialogOpen, setIsWarningDialogOpen] = React.useState(false)

  const [isWarningMessage, setIsWarningMessage] = React.useState('')
  const [isDuplicate, setIsDuplicate] = React.useState(false)

  const putApplicationById = async (id: string) => {
    try {
      const response = await API.PUT(`/Admin/applications/${id}`, data)
      return response.data
    } catch (error) {
      console.error('Error updating application:', error)
      throw error
    }
  }

  const postApplication = async () => {
    try {
      if (!data) {
        throw new Error('Data is required')
      }
      const response = await API.POST('/Admin/application', data)
      return response.application // Return the full application object with ID
    } catch (error) {
      console.error('Error creating application:', error)
      throw error
    }
  }

  // Modify postAccessList to check duplicates before posting
  const postAccessList = async (data: AccessList) => {
    try {
      const response = await API.POST('/Admin/access', data)
      return response.data
    } catch (error: any) {
      if (error.response?.status === 409 && data.windowsLogin) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getDuplicateMessage.accessList(data.windowsLogin),
        )
      } else if (error.response?.status === 400 && data.windowsLogin) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getUserNotFoundMessage.accessList(data.windowsLogin),
        )
      } else {
        setIsWarningMessage('An unexpected error occurred. Please try again.')
        setIsWarningDialogOpen(true)
      }

      console.error('Error saving access:', error)
      throw error
    }
  }

  const postExclusionList = async (data: ExclusionList) => {
    try {
      const response = await API.POST('/Admin/exclusion', data)
      return response.data
    } catch (error: any) {
      if (error.response?.status === 409 && data.windowsLogin) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getDuplicateMessage.exclusionList(data.windowsLogin),
        )
      } else if (error.response?.status === 400 && data.windowsLogin) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getUserNotFoundMessage.exclusionList(data.windowsLogin),
        )
      } else {
        setIsWarningMessage('An unexpected error occurred. Please try again.')
        setIsWarningDialogOpen(true)
      }

      console.error('Error saving access:', error)
      throw error
    }
  }

  const postAdGroupList = async (data: AdGroupList) => {
    try {
      const response = await API.POST('/Admin/adgroups', data)
      return response.data
    } catch (error: any) {
      if (error.response?.status === 409 && data.groupName) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getDuplicateMessage.adGroupList(data.groupName),
        )
      } else if (
        (error.response?.status === 400 && data.groupName) ||
        (error.response?.status === 404 && data.groupName)
      ) {
        handleDuplicateError(
          setIsWarningMessage,
          setIsWarningDialogOpen,
          setIsDuplicate,
          getUserNotFoundMessage.adGroupList(data.groupName),
        )
      } else {
        setIsWarningMessage('An unexpected error occurred. Please try again.')
        setIsWarningDialogOpen(true)
      }

      console.error('Error saving access:', error)
      throw error
    }
  }

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!data) return

    try {
      let applicationId = data.applicationId

      // If new application, create it and get the new ID
      if (!applicationId) {
        const newApplication = await postApplication()
        applicationId = newApplication.applicationId
      } else {
        // If existing application, update it
        await putApplicationById(applicationId)
      }

      // Create batch requests for each list type
      const accessPromises = newAccessItems.map((item) =>
        postAccessList({
          windowsLogin: item.windowsLogin,
          applicationId: applicationId,
          accessGranted: true,
        }),
      )

      const exclusionPromises = newExclusionItems.map((item) =>
        postExclusionList({
          windowsLogin: item.windowsLogin,
          applicationId: applicationId,
        }),
      )

      const adGroupPromises = newAdGroupItems.map((item) =>
        postAdGroupList({
          groupName: item.name,
          applicationId: applicationId,
        }),
      )

      // Wait for all requests to complete
      await Promise.all([
        ...accessPromises,
        ...exclusionPromises,
        ...adGroupPromises,
      ])

      if (onSave && formValues) {
        onSave(formValues, isDuplicate)
        setIsDuplicate(false)
        handleClose({}, 'escapeKeyDown')
      }
    } catch (error) {
      console.error('Failed to save:', error)
    }
  }

  const handleClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') return
    onClose({}, 'escapeKeyDown')
  }

  const handleWarningClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') return
    setIsWarningDialogOpen(false)
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        sx={{
          margin: { xs: '10px', sm: '89px' },
          '& .MuiPaper-root': { borderRadius: '8px' },
        }}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={handleSave}>
          {children}
          <DialogActions>
            <AdminButton
              variant='contained'
              color='success'
              type='submit'
              disabled={disabled}
            >
              Save
            </AdminButton>
            <AdminButton
              variant='contained'
              color='success'
              onClick={(event) => onClose(event, 'escapeKeyDown')}
            >
              Cancel
            </AdminButton>
          </DialogActions>
        </form>
      </Dialog>

      <DialogWarning
        open={isWarningDialogOpen}
        onClose={handleWarningClose}
        data={data}
        isDelete={false}
        dialogContent={isWarningMessage}
      />
    </>
  )
}

export default DialogDefault
