export const setWarningState = (
  setWarningMessage: (message: string) => void,
  setWarningDialogOpen: (open: boolean) => void,
  setDuplicate: (duplicate: boolean) => void,
  message: string,
) => {
  setWarningMessage(message)
  setWarningDialogOpen(true)
  setDuplicate(true)
}

export const handleDuplicateError = (
  setWarningMessage: (message: string) => void,
  setWarningDialogOpen: (open: boolean) => void,
  setDuplicate: (duplicate: boolean) => void = () => undefined,
  message = '',
) => {
  setWarningState(
    setWarningMessage,
    setWarningDialogOpen,
    setDuplicate,
    message,
  )
}

export const getDuplicateMessage = {
  accessList: (windowsLogin: string) =>
    `User ${windowsLogin} has a duplicate issue on AccessList`,
  exclusionList: (windowsLogin: string) =>
    `User ${windowsLogin} has a duplicate issue on ExclusionList`,
  adGroupList: (groupName: string) =>
    `Group ${groupName} has a duplicate issue on AdGroupList`,
}

export const getUserNotFoundMessage = {
  accessList: (windowsLogin: string) =>
    `User ${windowsLogin} on AccessList is not a valid windows login`,
  exclusionList: (windowsLogin: string) =>
    `User ${windowsLogin} on ExclusionList is not a valid windows login`,
  adGroupList: (groupName: string) =>
    `Group ${groupName} on AdGroupList is not a valid AD group`,
}
