import * as React from 'react'
import { TextField } from '@mui/material'

import { AccessList, ExclusionList, AdGroupList } from '../definitions/gridDef'
import {
  AdminButton,
  Label,
  ListContainer,
  InputBoxColumn,
} from './Styled/Administration'

interface UserAccessProps {
  formFields: any[]
  formValues: any
  accessList: AccessList[]
  exclusionList: ExclusionList[]
  adGroupList: AdGroupList[]
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  addToList: (type: 'access' | 'exclusion' | 'adGroup', value: string) => void
  removeFromList: (
    index: number,
    type: 'access' | 'exclusion' | 'adGroup',
  ) => void
}

const UserAccess: React.FC<UserAccessProps> = ({
  formFields,
  formValues,
  accessList,
  exclusionList,
  adGroupList,
  handleInputChange,
  addToList,
  removeFromList,
}) => {
  return (
    <InputBoxColumn>
      {/* Access Field (Column) */}
      {formFields
        .filter((field) => field.name === 'UserAccess')
        .map((field) => (
          <div key={field.id}>
            <Label>{field.label}</Label>
            <div className='input-box-column'>
              <TextField
                required={false}
                color='success'
                fullWidth
                id={field.id}
                name='UserAccess'
                type='text'
                size='small'
                value={
                  typeof formValues.data?.UserAccess === 'string'
                    ? formValues.data?.UserAccess
                    : ''
                }
                onChange={handleInputChange}
                placeholder={field.placeholder}
              />
              <AdminButton
                variant='outlined'
                color='success'
                onClick={() => addToList('access', formValues.data?.UserAccess)}
              >
                Add
              </AdminButton>
            </div>
            <ListContainer>
              <ul>
                {accessList.map((item, index) => (
                  <li key={index}>
                    {item.windowsLogin}
                    <AdminButton
                      color='error'
                      size='small'
                      onClick={() => removeFromList(index, 'access')}
                    >
                      Remove
                    </AdminButton>
                  </li>
                ))}
              </ul>
            </ListContainer>
          </div>
        ))}
      {/* Exclusion Field (Column) */}
      {formFields
        .filter((field) => field.name === 'Exclusion')
        .map((field) => (
          <div key={field.id}>
            <Label>{field.label}</Label>
            <div className='input-box-column'>
              <TextField
                color='success'
                required={false}
                fullWidth
                id={field.id}
                name='Exclusion'
                type='text'
                size='small'
                value={
                  typeof formValues.data?.Exclusion === 'string'
                    ? formValues.data?.Exclusion
                    : ''
                }
                onChange={handleInputChange}
                placeholder={field.placeholder}
              />
              <AdminButton
                variant='outlined'
                color='success'
                onClick={() =>
                  addToList('exclusion', formValues.data?.Exclusion)
                }
              >
                Add
              </AdminButton>
            </div>
            <ListContainer>
              <ul>
                {exclusionList &&
                  exclusionList.map((item, index) => (
                    <li key={index}>
                      {item.windowsLogin}
                      <AdminButton
                        color='error'
                        size='small'
                        onClick={() => removeFromList(index, 'exclusion')}
                      >
                        Remove
                      </AdminButton>
                    </li>
                  ))}
              </ul>
            </ListContainer>
          </div>
        ))}

      {/* AD Groups Field (Column) */}
      {formFields
        .filter((field) => field.name === 'AdGroup')
        .map((field) => (
          <div key={field.id}>
            <Label>{field.label}</Label>
            <div className='input-box-column'>
              <TextField
                color='success'
                required={false}
                fullWidth
                id={field.id}
                name='AdGroup'
                type='text'
                size='small'
                value={
                  typeof formValues.data?.AdGroup === 'string'
                    ? formValues.data?.AdGroup
                    : ''
                }
                onChange={handleInputChange}
                placeholder={field.placeholder}
              />
              <AdminButton
                variant='outlined'
                color='success'
                onClick={() => addToList('adGroup', formValues.data?.AdGroup)}
              >
                Add
              </AdminButton>
            </div>
            <ListContainer>
              <ul>
                {adGroupList.map((item, index) => (
                  <li key={index}>
                    {item.name}
                    <AdminButton
                      color='error'
                      size='small'
                      onClick={() => removeFromList(index, 'adGroup')}
                    >
                      Remove
                    </AdminButton>
                  </li>
                ))}
              </ul>
            </ListContainer>
          </div>
        ))}
    </InputBoxColumn>
  )
}

export default UserAccess
